<template>
  <div class="row mx-0">
    <div class="card-body pe-0 col-md-5 border-end">
      <!--      <pre>{{ logo }}</pre>-->
      <div class="d-flex mb-3 top justify-content-between align-items-center">
        <button class="btn btn-primary btn-sm" @click.prevent="handleAdd">
          <img
            class="hide-dark me-1"
            src="../../../assets/icons/icon-navigation-add-cropped.svg"
            alt=""
          />
          <img
            class="hide-light me-1"
            src="../../../assets/icons/icon-navigation-add-cropped-light.svg"
            alt=""
          />
          <span>{{ $t('message.add_branch') }}</span>
        </button>
      </div>

      <ul class="list-group list-group-flush">
        <li
          :class="activeBranch.id === branch.id ? 'active' : ''"
          @click="
            activeBranch = branch;
            formOpen = false;
          "
          class="
            d-flex
            justify-content-between
            cursor-pointer
            list-group-item list-group-item-action
            align-items-center
          "
          v-for="branch in branches"
          v-bind:key="branch"
        >
          <img
            v-if="baseurl && branch.logosmall"
            :src="baseurl + branch.logosmall.url"
            alt="logo small"
            style="max-height: 1rem; width: auto"
            class="me-1 img-fluid"
          />
          <span class="text-sentence">{{ branch.title }}</span>
          <span class="d-block ms-auto">
            <span v-if="branch.isdefault" style="font-size: 14px" class="c-okergeel c-200 px-1 py-0 rounded-0 border-0 border-end tip"
              >
              <span class="text-sm tip-content c-body fw-normal text-sentence">
            {{ $t("message.default_branch_tooltip") }}
          </span>
              <i class="fas fa-star">
              </i
            ></span>
            <button
              @click.stop.prevent="handleEdit(branch)"
              class="px-1 py-0 btn rounded-0 border-0 border-end"
            >
              <i class="fas fa-cog c-turkoois c-200"></i>
            </button>
            <span class="px-1">
              <button
                v-if="branch.isdefault !== true"
                @click.stop.prevent="handleDelete(branch)"
                class="c-koraalrood c-200 px-1 py-0 btn btn-link"
              >
                <i class="far fa-trash-alt c-error"></i>
              </button>
              <button
                disabled
                v-else
                @click.prevent=""
                class="disabled px-1 py-0 btn btn-link"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </span>
          </span>
        </li>
      </ul>
    </div>
    <div v-if="formOpen" class="card-body col-md-7 border-bottom-0">
      <form id="editBranch" class="row" @submit.prevent="saveBranch">
        <div class="col-md-12 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.name") }}</label>
          <input
            v-model="title"
            type="text"
            class="input-lg w-100"
            :class="invalidFields.title && 'border-danger'"
          />
          <small class="text-danger text-sentence" v-if="invalidFields.title">{{
            invalidFields.title
          }}</small>
        </div>
        <div class="col-md-6 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.email") }}</label>
          <input
            v-model="email"
            type="text"
            class="input-lg w-100"
            :class="invalidFields.title && 'border-danger'"
          />
          <small class="text-danger text-sentence" v-if="invalidFields.email">{{
            invalidFields.email
          }}</small>
        </div>
        <div class="col-md-6 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.phone") }}</label>
          <input v-model="phone" type="text" class="input-lg w-100" />
        </div>
        <div class="col-md-6 mb-3">
          <label for="" class="text-capitalize text-sentence">{{
            $t("message.street")
          }}</label>
          <input
            v-model="street"
            type="text"
            class="input-lg w-100"
            :class="invalidFields.title && 'border-danger'"
          />
          <small
            class="text-danger text-sentence"
            v-if="invalidFields.street"
            >{{ invalidFields.street }}</small
          >
        </div>
        <div class="col-md-2 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.nr") }}</label>
          <input
            v-model="nr"
            type="text"
            class="input-lg w-100"
            :class="invalidFields.title && 'border-danger'"
          />
          <small
            class="text-danger text-sentence"
            v-if="invalidFields.streetnr"
            >{{ invalidFields.streetnr }}</small
          >
        </div>
        <div class="col-md-2 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.bus") }}</label>
          <input v-model="bus" type="text" class="input-lg w-100" />
        </div>
        <div class="col-md-2 mb-3">
          <label for="" style="white-space: nowrap" class="text-capitalize text-sentence">{{
            $t("message.postal_code")
          }}</label>
          <input
            v-model="postalcode"
            type="text"
            class="input-lg w-100"
            :class="invalidFields.title && 'border-danger'"
          />
          <small
            class="text-danger text-sentence"
            v-if="invalidFields.postalcode"
            >{{ invalidFields.postalcode }}</small
          >
        </div>
        <div class="col-md-6 mb-3">
          <label for="" class="text-capitalize text-sentence">{{ $t("message.city") }}</label>
          <input v-model="city" type="text" class="input-lg w-100" />
        </div>
        <div class="col-md-6">
          <label for="" class="text-capitalize text-sentence">{{
            $t("message.country")
          }}</label>
          <select v-model="country" name="" id="" class="input-lg w-100">
            <option
                v-bind:key="listCountry"
                v-for="listCountry in countries"
                :value="listCountry"
                class="text-sentence"
            >
              {{ $t("message.country_" + listCountry) }}
            </option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <!--          <img class="img-fluid b-2" v-if="logo" :src="baseurl + logo.url" alt="">-->

          <label for="" class="text-sentence">{{ $t('message.logo_upload', { size: $t('message.large') }) }}</label>
          <drop-file
            :image-url="baseurl && logo ? baseurl + logo.url : ''"
            class="styled-input team-logo-input"
            ref="upload"
            @changeFile="handleChangeFile"
            @clearImage="handleClearImage('logo')"
            :name="'logo'"
          ></drop-file>
          <!--          <input name="logo" id="logo" type="file">-->
        </div>
        <div class="col-md-6 mb-3">
          <!--          <img class="img-fluid mb-2" v-if="logosmall" :src="baseurl + logosmall.url" alt="">-->
          <label for="" class="text-sentence">{{ $t('message.logo_upload', { size: $t('message.small') }) }}</label>
          <drop-file
            :image-url="baseurl && logosmall ? baseurl + logosmall.url : ''"
            class="styled-input team-logo-input"
            ref="upload"
            @changeFile="handleChangeFile"
            @clearImage="handleClearImage('logosmall')"
            :validation="{ maxSize: 250 }"
            :name="'logosmall'"
          ></drop-file>
          <!--          <input name="logosmall" id="logosmall" type="file">-->
        </div>
        <div class="col-12 d-flex">
          <checkbox v-model="isdefault"></checkbox>
          <span class="text-capitalize text-sentence">{{ $t('message.main_branch') }}</span>
        </div>
        <div class="mt-3 col-md-12 d-flex justify-content-end">
          <button
            v-if="!formLoading"
            @click.prevent="formOpen = false"
            class="btn btn-secondary me-2"
          >
            <span>{{ $t('message.cancel') }}</span>
          </button>
          <button
            v-if="!formLoading"
            type="submit"
            class="d-block btn btn-primary"
          >
            <span>{{ $t("message.save") }}</span>
          </button>
          <button v-else class="btn disabled d-flex" type="button" disabled>
            <div
              style="height: 12px; width: 12px"
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">{{ $t("message.loading") }}...</span>
            </div>
            <span class="ms-2">{{ $t('message.saving') }}</span>
          </button>
        </div>
      </form>
    </div>
    <div v-else-if="activeBranch" class="card-body col-md-7 border-bottom-0">
      <!--      <h5>{{ activeBranch.title }}-->
      <!--        <button @click.prevent="handleEdit(activeBranch)" class="p-0 btn btn-link"><i class="far fa-edit"></i></button>-->
      <!--        <button @click.prevent="handleDelete(activeBranch)" class="p-0 btn btn-link"><i class="fas fa-times"></i>-->
      <!--        </button>-->
      <!--      </h5>-->
      <div class="row">
        <div class="col-12" v-if="baseurl && activeBranch.logo">
          <img
            :src="baseurl + activeBranch.logo.url"
            alt="logo large"
            style="max-width: 300px"
            class="d-block mx-auto mb-2 img-fluid"
          />
        </div>
        <div class="col-12 text-center" v-else>
          <span class="c-paars mx-auto c-200 pt-2 mb-2 d-block">
            <i style="font-size: 60px" class="fas fa-building"></i>
          </span>
        </div>
        <div class="col">
          <ul class="list-group list-group-flush mb-3">
            <li v-if="activeBranch.title" class="list-group-item">

              <h5><i class="fa-fw me-2 far fa-building"></i> {{ activeBranch.title }}</h5>
            </li>
            <li v-if="activeBranch.email" class="list-group-item">
              <i class="fa-fw me-2 far fa-envelope"></i>
              {{ activeBranch.email }}
            </li>
            <li v-if="activeBranch.phone" class="list-group-item">
              <i class="fa-fw me-2 far fa-phone"></i>
              {{ activeBranch.phone }}
            </li>
            <li v-if="activeBranch.street" class="list-group-item">
              <div class="d-flex">
                <i class="fa-fw me-2 far fa-map-marker"></i>
                <div>
                  <p class="mb-1">{{ activeBranch.street }} {{ activeBranch.streetnr }}
                    {{ activeBranch.bus }}</p>
                  <p class="mb-1" v-if="activeBranch.city">{{ activeBranch.postalcode }} {{ activeBranch.city }}</p>
                  <p v-if="activeBranch.country" class="text-sentence">{{ $t("message.country_" + activeBranch.country) }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import Checkbox from "@/views/components/simple/Checkbox";
import DropFile from "@/views/components/simple/DropFile";
// import config from "@/../public/config.json";
import axios from "axios";

export default {
  components: { DropFile, Checkbox },
  async mounted() {
    await this.setBaseUrl();
    await this.getBranches();
    await this.getCountries();
  },
  methods: {
    async setBaseUrl() {
      const { data: config} = await axios.get('/config.json');
      this.baseurl = `${config.API_HOST}:${config.API_PORT}`;
    },
    handleClearImage(field) {
      this[field] = null;
    },
    handleChangeFile() {
      // this.logo = file
    },
    async getBranches() {
      const get = await this.axios.get("/companybranches");
      this.branches = get.data;
      this.activeBranch = this.branches[0];
    },
    async getCountries() {
      const f = await axios.get("/countries.json");
      this.countries = f.data;
    },
    handleDelete(branch) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: branch.id,
          title: branch.title,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.confirmDelete,
        },
      });
    },
    async uploadImage(id, type, formData) {
      // console.log('UPLOADING IMAGE FOR ' + type)
      const submitData = new FormData();

      for (var pair of formData.entries()) {
        if (pair[0] === type) {
          submitData.append("files", pair[1]);
        }
      }
      submitData.append("ref", "companybranches");
      submitData.append("refId", id);
      submitData.append("field", type);

      // for (var p of submitData.entries()) {
      //   console.log(p[0] + ', ' + p[1]);
      // }
      const file = submitData.get("files");

      if (file.name) {
        // console.log('uploading file', file)
        return await this.axios.post("/upload", submitData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      } else {
        return null;
      }
    },
    async confirmDelete() {
      await this.axios.delete("/companybranches/" + this.activeBranch.id);
      this.activeBranch = {};
      this.formOpen = false;
      this.getBranches();
    },
    validateBranch(branch) {
      let valid = true;
      const requiredFields = [
        "title",
        "email",
        "street",
        "streetnr",
        "postalcode",
      ];

      for (const field of requiredFields) {
        if (!branch[field]) {
          valid = false;
          let fieldname = field;
          if (field == "title") fieldname = "name";
          if (field == "streetnr") fieldname = "nr";
          if (field == "postalcode") fieldname = "postal code";
          this.invalidFields[field] = this.$t("message.field_required", {
            field: fieldname,
          });
        }
      }

      return valid;
    },
    async saveBranch(e) {
      this.invalidFields = {};
      this.formLoading = true;
      const branch = {
        title: this.title,
        isdefault: this.isdefault,
        phone: this.phone,
        email: this.email,
        street: this.street,
        streetnr: this.nr,
        bus: this.bus,
        postalcode: this.postalcode,
        city: this.city,
        country: this.country,
        logo: this.logo,
        logosmall: this.logosmall,
      };

      if (!this.validateBranch(branch)) {
        this.formLoading = false;
        return;
      }
      const formEl = e.target;
      const formData = new FormData(formEl);

      if (this.id) {
        const pu = await this.axios.put("/companybranches/" + this.id, branch);
        const l = await this.uploadImage(pu.data.id, "logo", formData);
        const s = await this.uploadImage(pu.data.id, "logosmall", formData);
        if (l) {
          branch.logo = l.data[0];
        }
        if (s) {
          branch.logosmall = s.data[0];
        }
      } else {
        const po = await this.axios.post("/companybranches", branch);
        const l = await this.uploadImage(po.data.id, "logo", formData);
        const s = await this.uploadImage(po.data.id, "logosmall", formData);
        if (l) {
          branch.logo = l.data[0];
        }
        if (s) {
          branch.logosmall = s.data[0];
        }
      }

      if (this.isdefault) {
        //todo pass new images from upload to object
        this.$store.commit("setCompany", branch);
      }
      this.formOpen = false;
      this.formLoading = false;
      this.getBranches();
    },
    handleAdd() {
      this.id = null;
      this.isdefault = false;
      this.activeBranch = {};
      this.formOpen = true;
      this.title = "";
      this.email = "";
      this.phone = "";
      this.street = "";
      this.nr = "";
      this.bus = "";
      this.postalcode = "";
      this.city = "";
      this.country = "";
      this.logo = "";
      this.logosmall = "";
    },
    handleEdit(branch) {
      // console.log('handle edit', branch)
      this.activeBranch = branch;
      this.id = branch.id;
      this.isdefault = branch.isdefault;
      this.formOpen = true;
      this.title = branch.title;
      this.email = branch.email;
      this.phone = branch.phone;
      this.street = branch.street;
      this.nr = branch.streetnr;
      this.bus = branch.bus;
      this.postalcode = branch.postalcode;
      this.city = branch.city;
      this.country = branch.country;
      this.logo = branch.logo;
      this.logosmall = branch.logosmall;
    },
  },
  data() {
    return {
      formLoading: false,
      countries: [],
      baseurl: null,
      isdefault: false,
      id: null,
      activeBranch: {},
      title: "",
      street: "",
      nr: "",
      bus: "",
      postalcode: "",
      email: "",
      phone: "",
      city: "",
      country: "",
      logo: "",
      logosmall: "",
      formOpen: false,
      branches: [],
      invalidFields: {},
    };
  },
};
</script>