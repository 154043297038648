<template>
  <div class="row">
    <div class="card-body col-md-12 border-bottom-0">
      <div class="d-flex justify-content-between align-items-center" v-if="settings">
        <div>
          <p class="mb-1 text-sentence d-block">{{ $t('message.personal_data_period') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.personal_data_period_description') }}</p>
        </div>
        <div class="d-flex border border-dark rounded-8 align-items-center">
          <input v-model="settings.savedatadays" placeholder="Unlimited" type="number" style="min-width: 0; width: 115px" class="border-0 pe-0" min="1"
                 max="999" />
          <span class="pe-2 text-sentence">{{ $t('message.days') }}</span>
        </div>
      </div>
      <div class="mt-3 col-md-12 d-flex justify-content-end">
        <button
            v-if="!formLoading"
            type="button"
            @click="handleSubmit"
            class="d-block btn btn-primary"
        >
          <span>{{ $t("message.save") }}</span>
        </button>
        <button v-else class="btn disabled d-flex" type="button" disabled>
          <div
              style="height: 12px; width: 12px"
              class="spinner-border"
              role="status"
          >
            <span class="visually-hidden">{{ $t('message.loading') }}...</span>
          </div>
          <span class="ms-2">{{ $t('message.saving') }}</span>
        </button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      formLoading: false,
    }
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch("submitSettings");
    },
  },
  computed: {
    settings() {
      // console.log('general setings',this.$store.getters.getSettings)
      return this.$store.getters.getSettings
        ? this.$store.getters.getSettings.data
        : null;
    },
  },
};
</script>