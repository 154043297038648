<template>
  <div class="page-settings mb-4">
    <div class="row m-0 content-row d-flex boxshadow ">
      <div class="boxshadow-0 card rounded-end-0 col-lg-3 ps-0 pe-0">
        <div class="rounded-0 card-header bgc-turkoois c-200 text-white overline">
          <button style="" class="no-focus d-inline d-lg-none me-3 p-0 navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#settingsnavbar" aria-controls="settingsnavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="">
                  <i class="text-white fas fa-bars"></i>
                </span>
          </button>
          <span class="text-sm">{{ $t('message.settings') }}</span>
        </div>

        <div class="card-body p-0 p-lg-3">

          <nav class="p-0 analysis-nav navbar navbar-expand-lg">

            <div class="collapse navbar-collapse" id="settingsnavbar">
              <ul class="p-3 navbar-nav nav nav-pills flex-column" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link active" id="settings-1-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-1"
                          type="button" role="tab" aria-controls="settings-1" aria-selected="true">
                    <span class="text-sentence">{{ $t('message.general') }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-1b-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-1b"
                          type="button" role="tab" aria-controls="settings-1b" aria-selected="true">
                    <span class="text-sentence">{{ $t('message.company') }}</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-2-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-2"
                          type="button" role="tab" aria-controls="settings-2" aria-selected="false">
                    <span class="text-sentence">{{ $t('message.privacy') }}</span>
                  </button>

                </li>
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-2b-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-2b"
                          type="button" role="tab" aria-controls="settings-2b" aria-selected="false">
                    <span class="text-sentence">{{ $t('message.emails') }}</span>
                  </button>

                </li>
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-3-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-3"
                          type="button" role="tab" aria-controls="settings-3" aria-selected="false">
                    <span class="text-sentence">{{ $t('message.faq') }}</span>
                  </button>

                </li>
                <li class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-4-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-4"
                          type="button" role="tab" aria-controls="settings-4" aria-selected="false">
                    <span class="text-sentence">{{ $t('message.field_options') }}</span>
                  </button>
                </li>
                <li v-if="user.managepermissions || user.managecompany" class="nav-item" role="presentation">
                  <button @click="handleSettingMenuClick" class="ps-3 w-100 text-start text-capitalize pill-turkoois nav-link" id="settings-5-tab" data-bs-toggle="pill"
                          data-bs-target="#settings-5"
                          type="button" role="tab" aria-controls="settings-5" aria-selected="false">
                    <span class="text-sentence">{{ $t('message.permissions') }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </nav>

        </div>
      </div>
      <div class="boxshadow-0 card rounded-start-0 col-lg-9 px-0">
        <div v-show="settingsLoaded" class="tab-content" id="pills-tabContent">

          <div class="tab-pane fade show active" id="settings-1" role="tabpanel" aria-labelledby="settings-1-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline">{{ $t('message.general') }}</span>
            </div>
            <div class="card-body col border-bottom-0">
              <general></general>
            </div>
          </div>

          <div class="tab-pane fade" id="settings-1b" role="tabpanel" aria-labelledby="settings-1b-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.company') }}</span>
            </div>
            <company></company>
          </div>

          <div class="tab-pane fade" id="settings-2" role="tabpanel" aria-labelledby="settings-2-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.privacy_data') }}</span>
            </div>
            <privacy></privacy>
          </div>

          <div class="tab-pane fade" id="settings-2b" role="tabpanel" aria-labelledby="settings-2b-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.emails') }}</span>
            </div>
            <emails></emails>
          </div>

          <div class="tab-pane fade" id="settings-3" role="tabpanel" aria-labelledby="settings-3-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.faq') }}</span>
            </div>
            <faq></faq>
          </div>

          <div class="tab-pane fade" id="settings-4" role="tabpanel" aria-labelledby="settings-4-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.field_options') }}</span>
            </div>
            <div class="card-body col border-bottom-0">
              <field-options></field-options>
            </div>
          </div>

          <div class="tab-pane fade" id="settings-5" role="tabpanel" aria-labelledby="settings-5-tab">
            <div class="rounded-0 card-header bgc-turkoois c-50">
              <span class="text-sm overline ">{{ $t('message.roles_permissions') }}</span>
            </div>
            <permissions></permissions>
          </div>
        </div>
        <div class="card-body position-relative" style="min-height: 150px" v-show="!settingsLoaded">
          <div class="position-absolute top-50 start-50 translate-middle container-spinner">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t("message.loading") }}...</span>
            </div>
          </div>
        </div>
        <!--        <div class="bg-white mb-3 card-footer border-top-0">-->
        <!--          <button @click="saveSettings" type="button" class="d-block ms-auto btn btn-primary">{{-->
        <!--              $t("message.save")-->
        <!--            }}-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import General from "@/views/components/settings/General";
import Company from "@/views/components/settings/Company";
import Privacy from "@/views/components/settings/Privacy";
import Faq from "@/views/components/settings/Faq";
import FieldOptions from "@/views/components/settings/FieldOptions";
import Permissions from "@/views/components/settings/Permissions";
import Emails from "@/views/components/settings/Emails";

export default {
  components: {Emails, Permissions, FieldOptions, Faq, Privacy, Company, General},
  computed: {
    settings() {
      return this.$store.getters.getSettings
    }
  },
  created() {
    this.getSettings()
  },
  data() {
    return {
      settingsLoaded: false
    }
  },
  methods: {
    async handleSettingMenuClick(){
      this.settingsLoaded = false
      await this.getSettings()
      this.settingsLoaded = true
    },
    async getSettings() {
      await this.$store.dispatch('fetchSettings')
      this.settingsLoaded = true
      this.mitt.emit('loadcomplete', { complete: true })
    },
    async saveSettings() {
      const {data} = await this.axios.put('/settings/1', {
        settings: this.settings,
        branches: this.branches,
        usertags: this.tags
      });
      this.settings = data.settings.data;
      this.listoptions = data.settings.listoptions;
    }
  }
}
</script>