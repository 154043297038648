<template>
  <div class="row" v-if="settings">
    <div class="col-xl-6 border-end col-lg-12">
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div :class="settings.actionsenabled || 'text-black-50'">
          <p class="mb-1 text-sentence text-sentence d-block">{{ $t('message.enable_actions') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.enable_actions_description') }}</p>
        </div>
        <checkbox v-model="settings.actionsenabled"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="settings.requirereview || 'text-black-50'">
          <p class="mb-1 text-sentence d-block">{{ $t('message.require_review') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.require_review_description') }}</p>
        </div>
        <checkbox v-model="settings.requirereview"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div :class="settings.resultbeforeend || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.show_results_before_end') }}</p>
<!--          <p class="text-sm">A personal review from a manager is required</p>-->
        </div>
        <checkbox v-model="settings.resultbeforeend"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-1 text-sentence d-block">{{ $t('message.minimum_users_segment') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.minimum_users_segment_description') }}</p>
        </div>
        <input v-model="settings.minusers" type="number" style="width: 7em" />
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-1 text-sentence d-block">{{ $t('message.minimum_users_team') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.minimum_users_team_message') }}</p>
        </div>
        <input v-model="settings.minusersTeam" type="number" style="width: 7em" min="3" />
      </div>

      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-1 text-sentence d-block">{{ $t('message.default_submission_anonimity') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.default_submission_anonimity_description') }}</p>
        </div>
        <select name="" v-model="settings.defaultanonimity" id="" style="width: 7em">
          <option value="private">{{ $t('message.private') }}</option>
          <option value="public">{{ $t('message.public') }}</option>
        </select>
      </div>
      <h4 class="text-sentence">{{ $t('message.statistics') }}</h4>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="settings.statsenabled || 'text-black-50'">
          <p class="mb-1 text-sentence d-block">{{ $t('message.enable_analytics') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.enable_analytics_description') }}</p>
        </div>
        <checkbox v-model="settings.statsenabled"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="(settings.statsenabled && settings.stats_heatmap) || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.enable_heatmap') }}</p>
        </div>
        <checkbox :disabled="!settings.statsenabled" v-model="settings.stats_heatmap"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="(settings.statsenabled && settings.stats_descriptives) || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.enable_descriptives') }}</p>
        </div>
        <checkbox :disabled="!settings.statsenabled" v-model="settings.stats_descriptives"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="(settings.statsenabled && settings.stats_network) || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.enable_network') }}</p>
        </div>
        <checkbox :disabled="!settings.statsenabled" v-model="settings.stats_network"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="(settings.statsenabled && settings.stats_histograms) || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.enable_histograms') }}</p>
        </div>
        <checkbox :disabled="!settings.statsenabled" v-model="settings.stats_histograms"></checkbox>
      </div>
      <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
        <div class="block text-md" :class="(settings.statsenabled && settings.stats_ria) || 'text-black-50'">
          <p class="mb-0 text-sentence">{{ $t('message.enable_ria') }}</p>
        </div>
        <checkbox :disabled="!settings.statsenabled" v-model="settings.stats_ria"></checkbox>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-1 text-sentence d-block">{{ $t('message.min_users_population_analytics') }}</p>
          <p class="text-sm mb-0 text-sentence d-block">{{ $t('message.min_users_population_analytics_description') }}</p>
        </div>
        <input v-model="settings.minimumPopulation" type="number" style="width: 7em" />
      </div>
<!--      <div class="mb-3">-->
<!--        <label class="" for=""-->
<!--          >Minimum amount of users for population analytics</label-->
<!--        >-->
<!--        <input-->
<!--          placeholder="50"-->
<!--          v-model="settings.minimumPopulation"-->
<!--          type="number"-->
<!--        />-->
<!--      </div>-->
    </div>
    <div class="col-xl-6 col-lg-12 mt-xl-0 mt-3">
      <h4 class="text-sentence d-block">{{ $t('message.tags') }}</h4>
      <div class="mb-3 pb-3 border-bottom">
        <p class="mb-1 text-sentence d-block">{{ $t('message.user_tags') }}</p>
        <p class="text-sm text-sentence d-block">{{ $t('message.user_tags_description') }}</p>
        <form @submit.prevent="handleAdd" class="d-flex w-100 mb-3">
          <input type="text" class="flex-grow-1" v-model="tagName" :placeholder="$t('message.newTag')" />
          <button class="ms-2 btn btn-sm btn-primary"><span>{{ $t('message.add_tag') }}</span></button>
        </form>
        <div class="tags" v-if="tagsLoaded">
          <h4>
            <span
                v-for="tag in tags"
                v-bind:key="tag"
                class="bgc-theme fw-normal c-300 me-2 mb-2 badge badge-secondary"
            >
              <form
                  @submit.prevent="confirmEdit"
                  v-if="editing === tag"
                  class="title me-2 c-body d-inline-flex"
              >
                <input class="me-2" type="text" v-model="editing.title" />
                <button class="btn btn-link me-2 p-0 c-body">
                  <i class="fas fa-check"></i>
                </button>
                <button
                    @click.prevent="editing = null"
                    type="button"
                    class="btn btn-link p-0 c-body"
                >
                  <i class="fas fa-times"></i>
                </button>
              </form>
              <span v-else>
                <span class="title me-2 c-body">{{ tag.title }}</span>
                <button
                    @click.prevent="handleEdit(tag)"
                    class="btn btn-link p-0 mx-1 c-body"
                >
                  <i class="fas fa-cog c-turkoois c-200"></i>
                </button>
                <button
                    @click.prevent="handleDelete(tag)"
                    class="btn btn-link p-0 ms-1 c-body"
                >
                  <i class="far fa-trash-alt c-error"></i>
                </button>
              </span>
            </span>
          </h4>
        </div>
        <div v-else class="position-relative" style="min-height: 20px">
          <div
              class="
              position-absolute
              top-50
              start-50
              translate-middle
              container-spinner
            "
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t("message.loading") }}...</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="mb-1 text-sentence d-block">{{ $t('message.message_tags') }}</p>
        <p class="text-sm text-sentence d-block">{{ $t('message.message_tags_description') }}</p>
        <form @submit.prevent="handleAdd('message')" class="d-flex mb-3">
          <input class="flex-grow-1" type="text" :placeholder="$t('message.newTag')" v-model="messagetagName" />
          <button class="ms-2 btn btn-sm btn-primary"><span>{{ $t('message.add_tag') }}</span></button>
        </form>
        <div v-if="messageTagsLoaded" class="tags">
          <h4>
            <span
                v-for="tag in messagetags"
                v-bind:key="tag"
                class="bgc-theme fw-normal c-300 me-2 mb-2 badge badge-secondary"
            >
              <form
                  @submit.prevent="confirmEdit('message')"
                  v-if="editing === tag"
                  class="title me-2 c-body d-inline-flex"
              >
                <input class="me-2" type="text" v-model="editing.tagtext" />
                <button class="btn btn-link p-0 c-body">
                  <i class="fas fa-check"></i>
                </button>
                <button
                    @click.prevent="editing = null"
                    type="button"
                    class="mx-1 btn btn-link p-0 c-body"
                >
                  <i class="fas fa-times"></i>
                </button>
              </form>
              <span v-else>
                <span class="title me-2 c-body">{{ tag.tagtext }}</span>
                <button
                    @click.prevent="handleEdit(tag)"
                    class="mx-1 btn btn-link p-0 c-body"
                >
                  <i class="fas fa-cog c-turkoois c-200"></i>
                </button>
                <button
                    @click.prevent="handleDelete(tag, 'message')"
                    class="ms-1 btn btn-link p-0 c-body"
                >
                  <i class="far fa-trash-alt c-error"></i>
                </button>
              </span>
            </span>
          </h4>
        </div>
        <div v-else class="position-relative" style="min-height: 20px">
          <div
              class="
              position-absolute
              top-50
              start-50
              translate-middle
              container-spinner
            "
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t("message.loading") }}...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 col-md-12 d-flex justify-content-end">
      <button
        v-if="!formLoading"
        type="button"
        @click="handleSubmit"
        class="d-block btn btn-primary"
      >
        <span>{{ $t("message.save") }}</span>
      </button>
      <button v-else class="btn disabled d-flex" type="button" disabled>
        <div
          style="height: 12px; width: 12px"
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
        <span class="ms-2">{{ $t('message.saving') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/views/components/simple/Checkbox";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  components: { Checkbox },
  methods: {
    async confirmDelete(id) {
      // console.log(id);
      await this.axios.delete("/usertags/" + id);
      this.getTags();
    },
    async confirmMessagetagDelete(id) {
      await this.axios.delete("/tags/" + id);
      this.getMessageTags();
    },
    async confirmEdit(type = "user") {
      if (type == "message") {
        await this.axios.put("/tags/" + this.editing.id, {
          tagtext: this.editing.tagtext,
        });
      } else {
        await this.axios.put("/usertags/" + this.editing.id, {
          title: this.editing.title,
        });
      }

      this.editing = null;
      this.getTags();
    },
    async handleAdd(type = "user") {
      if (type == "message") {
        await this.axios.post("/tags", {
          tagtext: this.messagetagName,
        });
        this.messagetagName = "";
        this.getMessageTags();
      } else {
        await this.axios.post("/usertags", {
          title: this.tagName,
        });
        this.tagName = "";
        this.getTags();
      }
    },
    handleEdit(tag) {
      this.editing = tag;
    },
    handleDelete(tag, type = "user") {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: tag.id,
          title: type == "message" ? tag.tagtext : tag.title,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal:
            type == "message"
              ? this.confirmMessagetagDelete
              : this.confirmDelete,
        },
      });
    },
    async getTags() {
      this.tagsLoaded = false;
      const get = await this.axios.get("/usertags?isHidden_ne=true");
      this.tags = get.data;
      this.tagsLoaded = true;
    },
    async getMessageTags() {
      this.messageTagsLoaded = false;
      const messageget = await this.axios.get("/tags");
      this.messagetags = messageget.data;
      this.messageTagsLoaded = true;
    },
    async handleSubmit() {
      this.formLoading = true;
      await this.$store.dispatch("submitSettings");
      this.formLoading = false;
    },
  },
  mounted() {
    this.getTags();
    this.getMessageTags();
  },
  computed: {
    settings() {
      // console.log('general setings', this.$store.getters.getSettings)
      return this.$store.getters.getSettings
        ? this.$store.getters.getSettings.data
        : null;
    },
  },
  data() {
    return {
      tagsLoaded: false,
      messageTagsLoaded: false,
      editing: null,
      tagName: "",
      messagetagName: "",
      tags: [],
      messagetags: [],
      formLoading: false,
    };
  },
};
</script>