<template>
  <div class="position-relative">
    <div
      class="
        p-3
        d-flex
        align-items-center
        form-control
        styled-input
        justify-content-center
      "
      style="min-height: 150px; height: auto; cursor: pointer"
      @dragover.prevent="
        () => {
          return;
        }
      "
      @dragenter.prevent="
        () => {
          return;
        }
      "
      @click.prevent="clickHandler"
      @drop.prevent="dropHandler"
    >
      <img
        class="img-fluid"
        @load="checkValidity"
        :id="'preview-img-' + name"
        v-show="preview"
        :src="imageUrl"
        alt=""
      />
      <span v-show="!preview && !error" class="text-sentence">{{
        $t("message.drop_your_image_here")
      }}</span>
      <span v-show="fileUrl && !preview && error" class="text-sentence">{{
        $t("message." + error, { amt: validation && validation.maxSize })
      }}</span>
    </div>
    <input
      class="d-none file-input-field"
      :required="required"
      @change="changeHandler"
      :id="'upload-' + name"
      :name="name"
      type="file"
    />
    <div
      class="position-absolute top-0 end-0 me-1 mt-1 d-flex align-items-center"
    >
      <button
        @click.prevent="removeImage"
        type="button"
        class="
          border-0
          d-block
          m-auto
          btn-sm
          small_btn small
          btn btn_small btn-danger
        "
        v-if="imageUrl || fileUrl"
      >
        <!--{{ $t('message.delete') }}-->
        <i class="far fa-trash-alt"></i>
      </button>
    </div>
    <span v-if="change" class="text-danger text-sm text-sentence"
      ><i class="far fa-info-circle"></i>
      {{ $t("message.unsaved_tooltip") }}</span
    >
    <!--    file: {{fileUrl}}-->
  </div>
</template>
<script>
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  data() {
    return {
      fileUrl: "",
      preview: this.imageUrl.length,
      error: "",
      change: false,
    };
  },
  props: {
    required: {
      Boolean,
      default() {
        return false;
      },
    },
    imageUrl: {
      String,
      default() {
        return "";
      },
    },
    name: {
      String,
      default() {
        return "files";
      },
    },
    validation: {
      Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    removeImage() {
      const fileInput = document.querySelector(`#upload-${this.name}`);
      const img = document.querySelector(`#preview-img-${this.name}`);
      if (!this.fileUrl) {
        //console.log('removing old image as well')
        this.$store.dispatch("openModal", {
          component: ConfirmDelete,
          options: { centered: true },
          properties: {
            id: 0,
            title: this.name == "logo" ? "logo" : "small logo",
          },
          events: {
            submitModal: () => {
              this.change = true;
              this.preview = false;
              this.$emit("clearImage");
            },
          },
        });
      } else {
        this.change = true;
        img.src = this.imageUrl;
        fileInput.value = "";
        // this.preview = false;
        this.fileUrl = "";
        //console.log('emitting change',null)
        this.$emit("changeFile", null);
      }
    },
    changeHandler(evt) {
      // console.log('change', evt)
      const files = evt.target.files;
      const img = document.querySelector(`#preview-img-${this.name}`);
      let _vm = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function () {
          img.src = fr.result;
          _vm.fileUrl = fr.result;
          // console.log('file', _vm.fileUrl)
        };

        fr.readAsDataURL(files[0]);
        this.preview = true;
        this.change = true;
        //console.log('emitting change',files[0])
        this.$emit("changeFile", files[0]);
      }
    },
    clickHandler() {
      const fileInput = document.querySelector(`#upload-${this.name}`);
      fileInput.click();
    },
    dropHandler(evt) {
      // console.log('drop handler', evt);
      const files = evt.dataTransfer.files;
      const fileInput = document.querySelector(`#upload-${this.name}`);
      const img = document.querySelector(`#preview-img-${this.name}`);
      fileInput.files = evt.dataTransfer.files;

      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function () {
          // console.log('filereader result', fr.result)
          img.src = fr.result;
        };
        fr.readAsDataURL(files[0]);
        this.preview = true;
        this.change = true;
        //console.log('emitting change',files[0])
        this.$emit("changeFile", files[0]);
      }

      evt.preventDefault();
    },
    checkValidity(e) {
      const img = e.currentTarget;
      let valid = true;

      //console.log(img);

      if (this.validation) {
        if (
          this.validation.maxSize &&
          (img.width > this.validation.maxSize ||
            img.height > this.validation.maxSize)
        ) {
          valid = false;
          this.preview = false;
          this.error = "img_max_size";
        }
      }

      return valid;
    },
  },
};
</script>
