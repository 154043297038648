<template>
  <div class="row">
    <div class="card-body pe-0 col-md-5 border-bottom-0">
<!--      <label for="">Pages</label>-->

      <ul class="list-group list-group-flush">
        <li
          :class="activePage === page ? 'active' : ''"
          v-for="page in pages"
          class="
            text-capitalize
            cursor-pointer
            list-group-item list-group-item-action
          "
          @click="setActive(page)"
          v-bind:key="page"
        >
          <span>{{ page }}</span>
        </li>
      </ul>
    </div>
    <div
      v-if="activePage"
      class="card-body col-md-7 border-start border-bottom-0"
    >
      <h4 class="text-sentence">{{ activePage }}</h4>
<!--      <label for="">Content</label>-->
      <tiptap v-model="faq[activePage]" />
      <!--      <textarea v-model="faq[activePage]" name="" id="" cols="30" rows="10" class="w-100"></textarea>-->
      <div class="mt-3">
        <button
          v-if="!loading"
          @click="saveFaq"
          class="ms-auto btn btn-primary d-block"
        >
          <span>{{ $t('message.save') }}</span>
        </button>
        <button
          v-else
          class="btn disabled ms-auto d-block"
          type="button"
          disabled
        >
          <div
            style="height: 12px; width: 12px"
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">{{ $t('message.loading') }}...</span>
          </div>
          <span class="ms-2">{{ $t('message.saving') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Tiptap from "@/views/components/simple/Tiptap";

export default {
  components: { Tiptap },
  data() {
    return {
      loading: false,
      activeFaq: null,
      activePage: "segments",
      pages: [
        "segments",
        "vitamins",
        "settings",
        "checkup",
        "analytics",
        "users",
        "profile",
        "checkups",
        "dashboard",
        "schedule",
        "actions",
        "tree",
      ],
    };
  },
  methods: {
    async saveFaq() {
      this.loading = true;
      this.settings.localized[this.$i18n.locale].faq = this.faq;
      await this.axios.put("/settings/" + this.settings.id, this.settings);
      this.loading = false;
    },
    setActive(page) {
      this.activePage = page;
      if (!this.faq[page]) {
        this.faq[page] = "";
      }
      this.activeFaq = this.faq[page];
    },
  },
  computed: {
    faq() {
      // console.log(this.settings);
      // console.log(this.$i18n.locale);
      if (this.settings) {
        if (
          this.settings.localized[this.$i18n.locale] &&
          this.settings.localized[this.$i18n.locale].faq
        ) {
          return this.settings.localized[this.$i18n.locale].faq;
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
    settings() {
      return this.$store.getters.getSettings;
    },
  },
};
</script>