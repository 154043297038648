<template>
  <div class="row" v-if="settings">
    <div class="card-body pe-0 col-md-5 border-bottom-0">
      <div class="mb-3">
<!--        <h4>System emails</h4>-->
        <ul class="list-group list-group-flush">
          <li
            @click="setActive(page)"
            class="cursor-pointer list-group-item list-group-item-action"
            :class="activePage === page ? 'active' : ''"
            v-for="page in pages"
            :key="page"
          >
            <span class="text-sentence">{{ page.replace("_", " ") }}</span>
          </li>
          <!--          <li>User registration</li>-->
          <!--          <li>Reset password</li>-->
          <!--          <li>New checkup available</li>-->
        </ul>
      </div>
    </div>
    <div
      v-if="activePage"
      class="border-start card-body col-md-7 border-bottom-0"
    >
      <h4>
        <span class="text-sentence">{{ activePage.replace("_", " ") }}</span>
      </h4>
      <div class="mb-3">
        <label for="" class="text-sentence">{{ $t('message.subject') }}</label>
        <input
          v-model="emails[activePage]['subject']"
          class="w-100"
          type="text"
        />
      </div>
      <div>
        <label for="" class="text-sentence">{{ $t('message.message') }}</label>
        <tiptap v-model="emails[activePage]['content']" />
        <!--        <textarea v-model="emails[activePage]['content']" class="w-100" name="" id="" cols="30" rows="10"></textarea>-->
        <div class="mt-3">
<!--          <form class="d-flex mb-3" @submit.prevent="sendTestMail">-->
<!--            <input type="email" class="flex-grow-1 me-2" :placeholder="$t('message.send_email_to')">-->
<!--            <button class="ms-auto btn btn-secondary d-inline-block">Send test email</button>-->
<!--          </form>-->
          <button
              v-if="!loading"
              @click="saveEmails"
              class="ms-auto btn btn-primary d-block"
          >
            <span>{{ $t('message.save') }}</span>
          </button>
          <button
              v-else
              class="btn disabled ms-auto d-block"
              type="button"
              disabled
          >
            <div
                style="height: 12px; width: 12px"
                class="spinner-border"
                role="status"
            >
              <span class="visually-hidden">{{ $t('message.loading') }}...</span>
            </div>
            <span class="ms-2">{{ $t('message.saving') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tiptap from "@/views/components/simple/Tiptap";

export default {
  components: { Tiptap },
  data() {
    return {
      loading: false,
      activeEmail: null,
      activePage: "user_registration",
      // pages: [
      //     "user_registration",
      //     "reset_password",
      //     "new_checkup"
      // ]
    };
  },
  methods: {
    async sendTestMail() {
      console.log(window.location.origin, this.activePage);
    },
    async saveEmails() {
      this.loading = true;
      // console.log(this.emails);
      // this.settings.localized[this.$i18n.locale].faq = this.faq;
      await this.$store.dispatch("submitSettings");
      this.loading = false;
    },
    setActive(page) {
      this.activePage = page;
      if (!this.emails[page]) {
        this.emails[page] = "";
      }
      this.activeEmail = this.emails[page];
    },
  },
  computed: {
    emails() {
      if (
        this.settings &&
        this.settings.localized[this.$i18n.locale] &&
        this.settings.localized[this.$i18n.locale].emails
      ) {
        return this.settings.localized[this.$i18n.locale].emails;
      } else {
        return {};
      }
    },
    pages() {
      return Object.keys(this.emails);
    },
    settings() {
      return this.$store.getters.getSettings;
    },
  },
};
</script>
<style lang="scss">
.list-group-item {
  border-color: #d9dce8;
  border-right-width: 4px !important;
  border-right-color: transparent;

  &.active {
    border-top: 0;
    border-color: #d9dce8 !important;
    background: #c9edea50 !important;
    border-right-color: #7bcdc7 !important;
  }
}
</style>