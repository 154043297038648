<template>
  <div class="row">
    <div class="card-body pe-0 col-md-5 border-bottom-0">
<!--      <label for="">Permissions</label>-->
      <ul class="list-group list-group-flush">
        <li v-for="permission in permissions"
            class="text-capitalize cursor-pointer list-group-item list-group-item-action"
            v-bind:key="permission"
            :class="activePermission === permission ? 'active' : ''"
            @click="setActivePermission(permission)">
          <span class="text-sentence">{{ $t('message.'+permission.field) }}</span>
        </li>
      </ul>
    </div>
    <div class="card-body col-md-7 border-bottom-0 border-start">
      <div class="">
        <h4 class="me-3 text-capitalize text-sentence">{{ $t('message.'+activePermission.field) }}</h4>

      </div>
      <p class="">
        {{ activePermission.body }}
      </p>
      <label for="" class="text-sentence">{{ $t('message.users_with_permissions') }}</label>

      <select-search
          :value="'id'"
          :label="['lastname','firstname']"
          :ajax="true"
          :query="'/users'"
          @selectOption="addPermission"
          ref="permissionInput"
          :placeholder="$t('message.give_user_permission')"></select-search>
      <div class="mt-2 text-center" v-if="isLoading">
        <div class="spinner-border" role="status" style="height: 16px; width: 16px">
        <span class="visually-hidden">{{ $t('message.loading') }}...</span>
      </div>
      </div>
      <ul v-else class="mt-2 list-group list-group-flush">
        <li class="list-group-item" v-bind:key="user" v-for="user in scheduleUsers">
          <div class="d-flex justify-content-between">
            <span>{{ user.lastname }} {{ user.firstname }}</span>
            <button @click="handleDelete(user)" class="btn btn-link px-1 py-0 c-body">
              <i class="far fa-trash-alt c-error"></i>
            </button>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>
<script>
import SelectSearch from "@/views/components/simple/SelectSearch";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  components: {SelectSearch},
  data() {
    return {
      activePermission: {},
      scheduleUsers: [],
      permissions: [
        {body: '', field: 'manageschedule'},
        {body: '', field: 'manageusers'},
        {body: '', field: 'manageteams'},
        {body: '', field: 'managesegments'},
        {body: '', field: 'manageanalytics'},
        {body: '', field: 'managesettings'},
        {body: '', field: 'managepermissions'},
        {body: '', field: 'managecompany'},
      ],
      isLoading: false,
    }
  },
  created() {
    this.isLoading = true
    this.activePermission = this.permissions[0]
    this.getUsers()
  },
  methods: {
    async addPermission(user) {
      this.isLoading = true
      await this.axios.put('/customuser/permissions/' + user.id, {
        [this.activePermission.field]: true
      });
      this.getUsers()
    },
    async removePermission(id) {
      this.isLoading = true
      await this.axios.put('/customuser/permissions/' + id, {
        [this.activePermission.field]: false
      })
      this.getUsers()
    },
    handleDelete(user) {
      this.$store.dispatch('openModal',
          {
            component: ConfirmDelete,
            properties: {
              id: user.id,
              title: user.firstname + ' ' + user.lastname,
            },
            options: {
              centered: true
            },
            events: {
              submitModal: this.removePermission
            }
          }
      )
    },
    setActivePermission(permission) {
      this.isLoading = true
      this.activePermission = permission
      this.$refs.permissionInput.search = "";
      this.getUsers()
    },
    async getUsers() {
      const get = await this.axios.get('/users?' + this.activePermission.field + '=true')
      this.scheduleUsers = get.data
      this.isLoading = false;
    },
  }
}
</script>