<template>
  <div class="row">
    <div
      class="col-md-4 mb-3 listoption"
      v-bind:key="listoption"
      v-for="(listoption, listInd) in listoptions"
    >
      <h4 class="text-sentence">{{ $t("message." + listoption.key) }}</h4>
      <form @submit.prevent="handleAdd($event, listInd)" class="d-flex mb-1">
        <input
          :id="'add-key-' + listoption.key"
          :name="'add-key-' + listoption.key"
          class="key w-50 rounded-end-0"
          type="text"
          :placeholder="$t('message.label')"
        />
        <input
          :id="'add-value-' + listoption.key"
          :name="'add-value' + listoption.key"
          class="val w-50 rounded-start-0 border-start-0"
          type="text"
          :placeholder="$t('message.value')"
        />
        <button class="btn-sm btn ms-2 btn-secondary">
          <span>{{ $t("message.add") }}</span>
        </button>
      </form>
      <ul class="listoption-list list-group list-group-flush">
        <li
          class="listoption-list-item list-group-item"
          v-for="(option, index) in listoption.options"
          :value="option.value"
          v-bind:key="option"
        >
          <div class="d-flex w-100 justify-content-between">
            <span :data-value="option.value" :data-key="option.key">
              <span class="d-flex" v-if="editing === option">
                <input
                  :placeholder="
                    $t('message.translate') + ' \'' + option.key + '\''
                  "
                  type="text"
                  v-model="localizedLabels[option.key]"
                />
                <button
                  @click="
                    () => {
                      this.editing = null;
                      this.saveSettings();
                    }
                  "
                  class="ms-2 btn btn-link p-0 c-body"
                >
                  <i class="fas fa-check"></i>
                </button>
              </span>
              <span v-else class="text-sentence d-inline">
                {{
                  localizedLabels && localizedLabels[option.key]
                    ? localizedLabels[option.key]
                    : option.key + " (" + $t("message.not_translated") + ")"
                }}
              </span>
            </span>
            <div class="actions">
              <button
                v-if="!editing"
                @click="handleEdit(listInd, index, option)"
                class="btn border-0 rounded-0 p-0 c-body border-end mx-1 pe-1"
              >
                <i class="fas fa-cog c-turkoois c-200"></i>
              </button>
              <button
                v-if="!editing"
                @click="handleDelete(listInd, index, option)"
                class="btn p-0 border-0 mx-1 c-body"
              >
                <i class="far fa-trash-alt c-error" ></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";

export default {
  data() {
    return {
      editing: null,
    };
  },
  methods: {
    async saveSettings() {
      await this.axios.put("/settings/" + this.settings.id, this.settings);
    },
    async handleAdd(e, listind) {
      const inputdata = e.target.querySelector("input.key");
      const inputval = e.target.querySelector("input.val");
      const data = inputdata.value;
      const val = inputval.value || data;
      if (data && val) {
        this.listoptions[listind].options.push({
          key: data,
          value: val,
        });

        this.localizedLabels[data] = data;
        await this.saveSettings();

        inputdata.value = "";
        inputval.value = "";
      }
    },

    confirmDelete(indices) {
      this.listoptions[indices[0]].options.splice(indices[1], 1);
      this.axios.put("/settings/" + this.settings.id, this.settings);
    },
    handleEdit(listind, index, option) {
      this.editing = option;
    },
    handleDelete(listind, index, option) {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: [listind, index],
          title: option.key,
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.confirmDelete,
        },
      });
    },
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings;
    },
    localizedLabels() {
      if (this.settings) {
        if (this.settings.localized[this.$i18n.locale]) {
          // console.log('settings',this.settings.localized)
          return this.settings.localized[this.$i18n.locale].listtranslations;
        } else {
          // console.log('settings for locale not found',this.settings.localized)
          return null;
        }
      } else {
        return null;
      }
    },
    listoptions() {
      if (this.settings) {
        return this.settings.listoptions;
      } else {
        return null;
      }
    },
  },
};
</script>